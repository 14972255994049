/*.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pointer {
    cursor : pointer;
}

!* 기본 설정*!
.a {text-decoration:none; color:#000000;}
.a:hover{color:#ff0000;}

!* nav tag *!

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

.switch-wrapper {
    position: relative;
}

.switch-wrapper > div {
    position: absolute;
}