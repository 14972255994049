@import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: Nanum Gothic, Dotum, 돋움, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}
code {
  font-family: Dotum, 돋움, source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

/** 공통 **/
.contents {
  /* min-height: 100%; */
  padding: 153px 12px 100px;
}

.contents-full {
  /* min-height: 100%; */
  padding: 60px 0 100px;
}

.title {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: bold;
}

.bottom {
  top: auto !important;
  bottom: 0 !important;
}

.bottom .bottom-button {
  padding: 0;
  height: 56px;
}

.bottom .bottom-button-title {
  font-size: 18px;
  font-weight: bold;
}

.bottom .bottom-button-price {
  font-size: 16px;
  text-align: center;
  line-height: 56px;
}

.price-color {
  color: #f50057;
}

.bottom button {
  font-size: 15px;
  font-weight: 300;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-fix::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

/* 에러 페이지 */
.error-container {
  height: 100%;
  background-color: #f9f9f9;
  text-align: center;
}

.error-container .error-top {
  width: 100%;
  height: 280px;
  background-color: #6d83d6;
}

.error-container .error-content {
  margin-top: -130px;
}

.error-container .error-content .error-message {
  padding: 10px 15px;
}

.error-container .error-button {
  margin-top: 40px;
  text-align: center;
}

.error-container .error-button a {
  width: 160px;
  height: 60px;
  font-size: 17px;
}

/* 에러페이지 - 주문 준비중 */
.week0,
.holyday {
  color: red;
}
.week6 {
  color: blue;
}

/* 메인 페이지 */
.contents-main {
  position: relative;
  min-height: 100%;
  padding-top: 153px;
  /* padding-bottom: 265px; */
}

/* 메인 페이지 - 메뉴 팝오버 */
#simple-menu .menu-icon {
  width: 15px;
  margin-right: 12px;
}

/* 메인 페이지 - 메뉴 리스트 */
.menu-container {
  position: relative;
  background-color: #f9f9f9;
  padding-bottom: 10px;
}

.menu-container ul {
  padding: 10px;
}

.menu-container ul > li {
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #f0f0f0;
}

.menu-container .button-order {
  display: block;
  position: sticky;
  position: -webkit-sticky;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 0;
  width: 150px;
}

/* 메인 페이지 - 푸터 */
footer.footer-main {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: #ececec;
  padding: 10px;
}

/* 메뉴 페이지 - 제품정보 */
.custom-botton-group > button.Mui-selected {
  background-color: #0070cd !important;
  color: #ffffff !important;
}

.custom-botton-group > button {
  background-color: #f7f7f9 !important;
  border-color: #0070cd !important;
  color: #0070cd !important;
}

/* 메뉴 페이지 - 토핑/세트 리스트 */
.option-group-title {
  width: 100%;
}

.option-group .option-group-title {
  height: 50px;
  line-height: 50px;
  border: 1px solid #f0f0f0;
  background-color: #f9f9f9;
  padding: 0 15px;
  font-size: 18px;
  font-weight: bold;
}

.option-group .option-list {
  border: 1px solid #f0f0f0;
  border-top: none;
  padding: 0 15px;
}

/** 주문 상세 **/
.order-detail-top {
  height: 55px;
  line-height: 55px;
  padding: 0 16px;
  background-color: #00559c;
  color: #fff;
}

.order-detail-top-delivery {
  background-color: #a0843c !important;
}

.order-detail-top .wait-number {
  float: left;
  font-size: 20px;
}

.order-detail-top .order-date {
  float: right;
  text-align: right;
}

.step-container {
  height: 170px;
  padding-top: 30px;
}

.step-container:after {
  content: '';
  display: block;
  margin: -71px 12% 0;
  border-top: 1px dashed #fff;
}

.step-container .step-item {
  text-align: center;
}

.step-container .step-item .step-title {
  padding-top: 95px;
  color: #fff;
  font-size: 12px;
}

.step-container .step-item.step-payment {
  background: url(static/images/payment_icon_off.png) no-repeat center 0;
  background-size: 80px 80px;
}

.step-container .step-item.step-payment.active {
  background-image: url(static/images/payment_icon_on.png);
}

.step-container .step-item.step-order {
  background: url(static/images/order_icon_off.png) no-repeat center 0;
  background-size: 80px 80px;
}

.step-container .step-item.step-order.active {
  background-image: url(static/images/order_icon_on.png);
}

.step-container .step-item.step-ready {
  background: url(static/images/ready_icon_off.png) no-repeat center 0;
  background-size: 80px 80px;
}

.step-container .step-item.step-ready.active {
  background-image: url(static/images/ready_icon_on.png);
}

.step-container .step-item.step-pickup {
  background: url(static/images/pickup_icon_off.png) no-repeat center 0;
  background-size: 80px 80px;
}

.step-container .step-item.step-pickup.active {
  background-image: url(static/images/pickup_icon_on.png);
}

.step-container .step-item.step-payment-baydiamond {
  background: url(static/images/payment_icon_off_baydiamond.png) no-repeat center 0;
  background-size: 80px 80px;
}

.step-container .step-item.step-payment-baydiamond.active {
  background-image: url(static/images/payment_icon_on_baydiamond.png);
}

.step-container .step-item.step-order-baydiamond {
  background: url(static/images/order_icon_off_baydiamond.png) no-repeat center 0;
  background-size: 80px 80px;
}

.step-container .step-item.step-order-baydiamond.active {
  background-image: url(static/images/order_icon_on_baydiamond.png);
}

.step-container .step-item.step-ready-baydiamond {
  background: url(static/images/ready_icon_off_baydiamond.png) no-repeat center 0;
  background-size: 80px 80px;
}

.step-container .step-item.step-ready-baydiamond.active {
  background-image: url(static/images/ready_icon_on_baydiamond.png);
}

.step-container .step-item.step-pickup-baydiamond {
  background: url(static/images/pickup_icon_off_baydiamond.png) no-repeat center 0;
  background-size: 80px 80px;
}

.step-container .step-item.step-pickup-baydiamond.active {
  background-image: url(static/images/pickup_icon_on_baydiamond.png);
}

.order-detail-box {
  padding-top: 20px;
}

.order-detail-box .box-title {
  font-size: 18px;
  font-weight: 800;
  padding: 10px 15px;
}

.order-detail-box .box-contents {
  border: 1px solid #ddd;
  padding: 15px;
}

.order-detail-box .box-contents ul.menu-list {
  padding: 0 0 16px;
  border-bottom: 1px solid #cfcfcf;
}

.order-detail-box .box-contents ul.menu-list > li.menu-detail {
  padding: 15px 0;
  border-bottom: 1px dashed #cfcfcf;
}

.order-detail-box .box-contents ul.menu-list > li.menu-detail:last-child {
  border-bottom: none;
}

/* 구매 매장 리스트 */
#store-list-container {
  padding: 65px 10px 0 !important;
}

#store-list-container > .no-order-history-alert {
  height: 200px;
  line-height: 200px;
  text-align: center;
}

#store-list-container > ul.store-list > li {
  margin-bottom: 10px;
  padding: 0;
  border: 1px solid #f6f6f6;
  background-color: #fff;
}

#store-list-container > ul.store-list > li .store-info {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
}

#store-list-container > ul.store-list > li .store-info > .store-logo {
  position: relative;
  width: 132px;
  height: 90px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

#store-list-container > ul.store-list > li .store-info > .store-logo > .store-preparing {
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  z-index: 2;
}

#store-list-container > ul.store-list > li .store-info > .store-logo > .dim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

#store-list-container > ul.store-list > li .store-info > .store-detail {
  width: 100%;
  padding: 20px 15px;
}

#store-list-container > ul.store-list > li .store-info > .store-detail > .store-name {
  font-weight: 800;
  font-size: 18px;
}

#store-list-container > ul.store-list > li .store-info > .store-detail > .store-address {
  font-size: 12px;
}

#store-list-container > ul.store-list > li .store-button-container {
  width: 100%;
  height: 30px;
}

#store-list-container > ul.store-list > li .store-button-container > .store-button {
  text-align: center;
  line-height: 30px;
}

#store-list-container > ul.store-list > li .store-button-container > .store-button:first-child {
  border-right: 1px solid #f6f6f6;
}

/* 구매 매장 리스트 모달 */
#modal > .dim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 98;
}

#modal > .modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: #fff;
  z-index: 99;
}

#modal > .modal-container > .modal-title {
  position: relative;
  padding: 10px 20px;
  border-bottom: 2px solid #e5e5e5;
  font-size: 17px;
  font-weight: 900;
  line-height: 35px;
}

#modal > .modal-container > ul.modal-contents {
  list-style: none;
  margin: 0;
  padding: 10px 20px;
}

#modal > .modal-container > ul.modal-contents > li {
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
}

#modal > .modal-container > ul.modal-contents > li::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

#modal > .modal-container > ul.modal-contents > li > .label {
  float: left;
  width: 50px;
  color: #9d9d9d;
}

#modal > .modal-container > ul.modal-contents > li > .content {
  float: left;
  color: #000;
}

#modal > .modal-container > .modal-map {
  position: relative;
  padding: 15px;
}

#modal > .modal-container > .modal-map > #map {
  position: relative;
  width: 100%;
  height: 300px;
}

#user-guide {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 60px;
}

.terms-title {
  text-align: center;
}

.terms-contents {
  white-space: pre-wrap;
}

.service-container {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #0070cd;
  justify-content: center;
  align-items: center;
}
.service-container .item {
  text-align: center;
}
.service-container img {
  width: 60%;
  height: auto;
}
.service-container .title {
  margin-top: 20pt;
  text-align: center;
  color: #fff;
  font-size: 1rem;
}
.service-container .hilight {
  color: #fff700;
}
.yokohama-header-background {
  background: linear-gradient(135deg, #0cb6ff 0%, #2139e2 100%);
}
.yokohama-header-background-delivery {
  background: linear-gradient(135deg, #c4bc7c 0%, #a0843c 100%);
}
.yokohama-orderComplete-background {
  margin-top: '56px';
  padding: '35px 30px 91px';
  background: 'linear-gradient(#0070CD 210px, #fff 0%)';
}
.yokohama-orderComplete-background-delivery {
  margin-top: '56px';
  padding: '35px 30px 91px';
  background: 'linear-gradient(#c4bc7c 210px, #fff 0%)';
}
.top-order-waitTime-container {
  height: 48px;
  background-color: #f75757;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
}
.notice-base {
  font-family: 'Noto Sans JP' !important;
  text-align: center;
}
.notice-step-font {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  background: linear-gradient(135deg, #0cb6ff 0%, #2139e2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
